export const photos = [
  {
    src: "/wedding_img/wed_img_1.jpg?color=ffffff&title=0&byline=0",
    width: 3,
    height: 4,
  },
  {
    src: "/wedding_img/wed_img_2.jpg?color=ffffff&title=0&byline=0",
    width: 3,
    height: 4,
  },
  {
    src: "/wedding_img/wed_img_3.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_4.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_5.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_6.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_7.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_8.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_9.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_10.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_11.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_12.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_13.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_14.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_15.jpg?color=ffffff&title=0&byline=0",
    width: 3,
    height: 4,
  },
  {
    src: "/wedding_img/wed_img_16.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_18.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_19.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_20.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_21.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_22.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_23.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_24.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_25.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_26.jpg?color=ffffff&title=0&byline=0",
    width: 4,
    height: 3,
  },
  {
    src: "/wedding_img/wed_img_27.jpg?color=ffffff&title=0&byline=0",
    width: 3,
    height: 4,
  },
];
